.app {
  display: flex;
  height: 100vh;
  width: 100%;
}
.sidebar {
  flex: 0 0 150px;
  border: 1px solid #ccc;
}
.content {
  border: 1px solid #ccc;
  background: blue;
  flex-grow: 1;
}
