.top-table {
    width: 100%;
    min-width: unset !important;
}

.analytics-page-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    width: 100%;
}