.taxonomy {
  &-header {
    display: flex;
    align-items: center;
  }
  &-title {
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
  }
  &-table {
    margin-bottom: 80px;
    .uploader-button {
      position: absolute;
      width: 25px;
      height: 25px;
      right: 4px;
      top: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.ant-modal-footer {
  display: none;
}
