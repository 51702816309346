.home-btn {
  position: absolute;
  height: 42px;
  top: 10px;
  left: 10px;
}

.not-published-row {
  opacity: 0.4;
}

.published-row {
  opacity: 1;
  background: #fafafa;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border: 0.5px solid #e3e3e3 !important;
}
