body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 44px;
}

tr, td {
  max-height: 110px !important;
}

#preview {
  width: 55px;
}

.preview {
  &.landscape {
    width: 220px;
    height: 120px;
  }

  &.portrait {
    width: 120px;
    height: 160px;
  }
}

.actions-buttons {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.file-uploader {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    border-bottom: 1px solid #ccc;
  }

  &-list {
    display: flex;
    flex-direction: column;
  }
}

.assets-page-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-page {
  position: relative;
  width: 100%;

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
    background: #fff;
  }
}

.table-input {
  &:focus {
    z-index: 10;
    min-width: 200px;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 30px;
  }
}